import React from "react"
import marked from "marked"

interface Props {
  text: string
}

export default function RichText({ text }: Props) {
  const html: string = marked(text)
  return <div dangerouslySetInnerHTML={{ __html: html }} />
}
